<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M20.1207 10.0004C20.1207 12.4486 19.1482 14.7964 17.4171 16.5276C15.6859 18.2587 13.3381 19.2312 10.8899 19.2312M20.1207 10.0004C20.1207 7.55227 19.1482 5.20439 17.4171 3.47328C15.6859 1.74218 13.3381 0.769653 10.8899 0.769653M20.1207 10.0004H1.65918M10.8899 19.2312C8.4418 19.2312 6.09392 18.2587 4.36281 16.5276C2.6317 14.7964 1.65918 12.4486 1.65918 10.0004M10.8899 19.2312C12.5894 19.2312 13.9669 15.0979 13.9669 10.0004C13.9669 4.90298 12.5894 0.769653 10.8899 0.769653M10.8899 19.2312C9.19049 19.2312 7.81303 15.0979 7.81303 10.0004C7.81303 4.90298 9.19049 0.769653 10.8899 0.769653M10.8899 0.769653C8.4418 0.769653 6.09392 1.74218 4.36281 3.47328C2.6317 5.20439 1.65918 7.55227 1.65918 10.0004"
      stroke="currentColor"
      stroke-width="1.53846"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

